<div
  elTestId="jQcu11xiN-ScQ7UjSTGUB"
  class="flex flex-col gap-2.5 banner-container"
  [ngClass]="{
    'insight-content': (iotInsightService.actionTypeIsInsight$ | async)
  }"
  >
  @if (
    displaySystemMaintenanceBanner && (showSystemMaintenanceMessage$ | async)
    ) {
    <div
      elTestId="gXZbJCOHalYLsJ8dOFKh9"
      class="banner"
      >
      <app-system-banner
        [message]="(systemMaintenanceMsgHTML$ | async) || ''"
        [type]="(systemMaintenanceMsg$ | async)?.type || bannerType.INFO"
        (closed)="closeSystemBanner()"
        >
      </app-system-banner>
    </div>
  }

  @if (displaySubscriptionBanner && (showMaintenanceMessage$ | async)) {
    <div
      elTestId="6vD659vXSd5ABEDBSAiFB"
      class="banner"
      >
      <app-system-banner
        [message]="(subscriptionMaintenanceMsgHTML$ | async) || ''"
        [type]="(maintenanceMsg$ | async)?.type || bannerType.INFO"
        (closed)="closeSubscriptionBanner()"
        >
      </app-system-banner>
    </div>
  }
</div>
